.header{
    font-weight: bold;
    cursor:context-menu;
    color: #495057;
  }
  .buttonColor {
     color: white !important;
     background-color: #FE9810 !important;
     background-image: linear-gradient(#FE9810, #FE9810) !important;
     border-color: #FE9810 !important;;
    }
  
    .buttonCancelColor {
     color: white !important;
     background-color: #5A5A5A !important;
     background-image: linear-gradient(#5A5A5A, #5A5A5A) !important;
     border-color: #5A5A5A !important;;
    }
  
    .buttonDocumentTreeColor {
      color: white !important;
      background-color: #FE522E !important;
      background-image: linear-gradient(#FE522E, #FE522E) !important;
      border-color: #FE522E !important;;
    }
    .ePDqSK{
      background-color: transparent !important;
    }
    .hPRLgG{
      background-color: transparent !important;
    }
    .iERBAK{
      background-color: transparent !important;
    }
    .epTcix{
      background-color: transparent !important;
    }
    .eoNlSG{
      background-color: #e9ecef !important;
    }
    .fvXoEr {
      background-color: transparent !important;
    }
  
     .rdt_TableHeadRow {
      background-color:#e9ecef;
    } 
  
     .modal-header
  
     {
       height:50px;
     }
  
     .modal-title
     {
      line-height: 1 !important;
     }
  
    .cr-sidebar__content
    {
      padding-bottom: 10px !important;
    }
  
    .highlighter{cursor:pointer;}  
    
    .highlighter td{color: #69b1e0  }
  
  
     /* for CyberSecurityExcercise.js page */
  
    .table thead th{
      vertical-align: baseline;
      font-weight: 600;}
      .table{    font-size: 12px;
      text-align: center;}
      .breadcrumb-item+.breadcrumb-item::before {
      display: inline-block;
      padding-right: rem;
     
      content: ">";
  }
  .calendar
  {
       border: 1px solid black;
    padding: 2px;
      border-spacing: 10px;
    border-color:gray;
    
     
  
  
  }
  /* For Feb,July and Nov */
  .buttonCSE2 {
    background-color: #00b359;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;
  border-radius: 50%;
    width:47.8px;
      height:46px;
  
  }
  
  /* For Jan,Mar,Apr ,Jun,Aug,Sep,Oct,Dec */
  .buttonCSE1
  {
      background-color:#ffffff; 
      border: 1px solid;
      color:black;
  
  
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;	
  border-radius: 50%;
  width:47.8px;
      height:46px;
  
  }
  
   .row1 {
      
        border-radius:50%;
  border-spacing:15px;
       text-align:center;
   
   padding-left: 4px;
   padding-right: 4px;
  
    position:relative;
  
   }
   .row2 {
         border-radius:50%;
       text-align:center;
   padding: 0px;
  
    position:relative;
   }
   
   
   .row3 
   {
        border-radius:50%;
  padding: 0px;
  
    position:relative;
  
       text-align:center;
  
   }
   
  /* Previous button inside month header */
  .prev {
  font-size:18px; 
  text-align:center;
  }
  
  /* Next button */
   .next {
  text-align: center;
   font-size:18px;
  
  }
  
  p.xyz{
  font-size:9.5px; 
  left:100%;
   position:relative;	
  }
  
  .rst
  {
      font-size:9.5px;
      width: 50px;
  }
  
  .table-style{
      margin-bottom:30px; 
      width:100%; 
      border-spacing:10px;
      background-color: #e9ecef;
      
  }
  
  h6.exercise{
      
      margin-bottom:20px; 
      margin-top:30px;
      
  }
  
  .table-column
  {
      
  text-align:left;	
      
  }
  
  
  
  
  
  tr.row-background
  {
      background-color:#F5F5F5;
      
  }
  
  .underway
  {
      
      color:#FFCC00;
  }
  
  .calendar-header{
  background-color:#D3D3D3;
  
  }
  
  td.year{
      
      text-align:center;
  }
  
  .anchor-color
  {
      
      color:red;
  }
  
  .row-color
  {
      
      background-color:#F5F5F5;
  }
  .row-color1
  {
      
      background-color:#FFFFFF;
  }
  
  p.p-align {
      margin: 0;
  }
  
  .buttonCSE3 {
    position: relative;
    background-color:orange;
     padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;	
  border-radius: 50%;
  color:#ffffff;
  width:47.8px;
      height:46px;
  }
  .bell-icon {
    position: absolute;
    top: 19%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size:10px;
    width:20px;
  }
  
  .buttonCSE4 {
    position: relative;
    background-color:#3783f0;
     padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;	
  border-radius: 50%;
  color:#ffffff;
  width:47.8px;
      height:46px;
  }
  
  .spanCSE1width{
    margin-left: 0px;
  }
  
  .buttonCSE1:hover .overlay{
    opacity: 1;
  }
  
  .overlay{
    position: absolute;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  border-radius: 50%;
  color:#ffffff;
  width:41.8px;
      height:40px;
    bottom: 3px;
    right: 7px;
    opacity: 0;
    transition: .5s ease;
    background-color: #ffffff;
  }
  
  .NoColor{
    background-color: #ffffff;
  }
  
  .row1:focus{
    outline: none;
  }
  
  .buttonCSE5 {
    position: relative;
    background-color:red;
     padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;	
  border-radius: 50%;
  color:#ffffff;
  width:47.8px;
      height:46px;
  }
  
  .buttonCSE1:focus {
    outline: none;
  }
  
  .buttonCSE2:focus {
    outline: none;
  }
  .buttonCSE3:focus {
    outline: none;
  }
  .buttonCSE4:focus {
    outline: none;
  }
  .buttonCSE5:focus {
    outline: none;
  }
  
  .buttonCSE6{
    position: relative;
    background-color: #3783f0;
     padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;	
  border-radius: 50%;
  color:#ffffff;
  width:47.8px;
      height:46px;
  }
  
  .buttonCSE7{
    position: relative;
    background-color: #b5b3b3;
     padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0px;	
  border-radius: 50%;
  color:#ffffff;
  width:47.8px;
      height:46px;
  }
  
  .responsibilityNoBorder{
    border: 0px none transparent !important;
  }
  
  @media (min-width: 1600px){
    .textoverlap{
      position:relative;
      top:-4px;
      margin-bottom: -6px;
    }
  }
  .dashboardalignment
  {
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    position: relative;
     top: -17px;
    }
  